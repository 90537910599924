import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Session, Local } from '@/utils/storage';
import { checkStatus } from "@/api/helper/checkStatus";
import qs from 'qs';
import { useUserStoreWithOut } from '@/store/modules/user'
/**
 * @description：请求配置
 */
export enum ResultEnum {
	SUCCESS = 200,
	ERROR = 300,
	OVERDUE = 401,
	LOGOUT = 301,
	UNAUTH = 305,
	TIMEOUT = 60000,
	TYPE = "success"
}

const baseURL: string = import.meta.env.VITE_API_BASE_PATH + import.meta.env.VITE_API_PREFIX;
let t = (-new Date().getTimezoneOffset() / 60) == 8 ? 'Asia/Shanghai' : ''
let n = +new Date()
const config = {
	// 默认地址请求地址，可在 .env.  文件中修改
	baseURL: baseURL,
	// 设置超时时间（10s）
	timeout: ResultEnum.TIMEOUT as number,
	// 跨域时候允许携带凭证
	withCredentials: false,
	headers: {
		'Content-Type': 'application/json',
		platform: 'admin',
		timestamp: `${n}_${t}`,
	},
	paramsSerializer: {
		serialize(params: any) {
			return qs.stringify(params, { allowDots: true });
		},
	},
};
class RequestHttp {
	service: AxiosInstance;
	public constructor(config: AxiosRequestConfig) {
		// 实例化axios
		this.service = axios.create(config);
		/**
		 * @description 请求拦截器
		 * 客户端发送请求 -> [请求拦截器] -> 服务器
		 * token校验(JWT) : 接受服务器返回的token,存储到vuex/pinia/本地储存当中
		 */
		this.service.interceptors.request.use(
			(config: any) => {
				// 在发送请求之前做些什么 token
				if (Local.get('Authorization-token')) {
					config.headers!['token'] = `${Local.get('Authorization-token')}`;
				}
				config.headers!['language'] = `${Local.get('language') || 'zh_CN'}`;
				return config;
			},
			(error: AxiosError) => {
				return Promise.reject(error);
			}
		);


		/**
		 * @description 响应拦截器
		 *  服务器换返回信息 -> [拦截统一处理] -> 客户端JS获取到信息
		 */
		this.service.interceptors.response.use(
			(response: AxiosResponse) => {
				const { data, statusCode, statusMsg } = response.data;
				// * 登录失效（code == 401）
				if (statusCode == ResultEnum.OVERDUE || statusCode == ResultEnum.LOGOUT) {
					const userStore = useUserStoreWithOut()
					Local.clear(); // 清除浏览器全部临时缓存
					userStore.reset();
					ElMessage.error(statusMsg);
					location.reload();
					return Promise.reject(data);
				}
				// if(statusCode === ResultEnum.UNAUTH){
				// 	return Promise.reject(data);
				// }
				// * 全局错误信息拦截（防止下载文件得时候返回数据流，没有code，直接报错）
				if (statusCode && statusCode !== ResultEnum.SUCCESS) {
					ElMessage.error(statusMsg);
					return Promise.reject(data);
				}
				// 操作失败
				if (statusCode === ResultEnum.ERROR) {
					ElMessage.error(statusMsg);
					return Promise.reject(data);
				}
				// * 成功请求（在页面上除非特殊情况，否则不用在页面处理失败逻辑）
				return data;
			},
			async (error: AxiosError) => {
				const { response } = error;
				// 请求超时 && 网络错误单独判断，没有 response
				if (error.message.indexOf("timeout") !== -1) ElMessage.error("请求超时！请您稍后重试");
				if (error.message.indexOf("Network Error") !== -1) ElMessage.error('网络错误！请您稍后重试');
				// 根据响应的错误状态码，做不同的处理
				if (response) checkStatus(response.status);
				return Promise.reject(error);
			}
		);
	}

	// * 常用请求方法封装
	get<T = any>(url: string, params?: object, _object = {}): Promise<T> {
		return this.service.get(url, { params, ..._object });
	}

	post<T = any>(url: string, params?: object, _object = {}): Promise<T> {
		return this.service.post(url, params, _object);
	}
}

export default new RequestHttp(config);