import http from './request.ts'

import * as adminApi from './module/admin.js';
import * as centerApi from './module/center.js';
import * as regionApi from './module/region.js';
import * as agentApi from './module/agent.js';
import * as organApi from './module/organ.js';
export default {
  //通用接口
  post: (params) => http.post({ url: '/api/admin/granular/list1', params }),
  oauth: (params) => http.post(`/admin/login/account`, params),
  phoneLogin: (params) => http.post(`/admin/login/code`, params),
  loginCode: (params) => http.post(`/admin/account/loginCode`, params),
  wxQrcode: (params) => http.post(`/admin/account/wxQrcode`, params),
  findPassword: (params) => http.post(`/admin/account/findPassword`, params),
  findPasswordCode: (params) => http.post(`/admin/account/findPasswordCode`, params),
  updatePassword: (params) => http.post(`/admin/account/updatePassword`, params),
  updatePhone: (params) => http.post(`/admin/account/updatePhone`, params),
  updatePhoneCode: (params) => http.post(`/admin/account/updatePhoneCode`, params),
  checkTokenStatus: () => http.post(`/admin/account/checkTokenStatus`),
  uploadToken: () => http.post('/admin/account/uploadToken'),
  information: () => http.post('/admin/account/information'),
  updateInformation: (params) => http.post('/admin/account/updateInformation', params),
  uploadImg: (url, params, headers) => {
    return http.post(url, params, headers);
  },
  commonprescriptionlist: (params) => http.post('/common/prescription/list', params),
  commonprescriptiondetails: (params) => http.post('/common/prescription/details', params),
  commonprescriptionsave: (params) => http.post('/common/prescription/save', params),
  commonprescriptiondelete: (params) => http.post('/common/prescription/delete', params),
  commonmedicationsearch: (params) => http.post('/common/medication/search', params),

  commonmanufacturerList: (params) => http.post('/common/common/manufacturerList', params),

  discountsetManufactureDiscount: (params) => http.post('/common/discount/setManufactureDiscount', params),
  discountgetManufactureDiscount: (params) => http.post('/common/discount/getDiscount', params),

  commonorderList: (params) => http.post('/common/order/list', params),
  commonorderplace: (params) => http.post('/common/order/place', params),
  commonorderdetails: (params) => http.post('/common/order/details', params),
  commonorderuploadPrescriptionFile: (params) => http.post('/common/order/uploadPrescriptionFile', params),
  commonorderlastMedication: (params) => http.post('/common/order/lastMedication', params),
  commonorderfeeCalculate: (params) => http.post('/common/order/feeCalculate', params),

  commonorganwallets: (params) => http.post('/common/organ/wallets', params),
  commonorganwalletLogs: (params) => http.post('/common/organ/walletLogs', params),
  commonorganwalletlockWallet: (params) => http.post('/common/organ/lockWallet', params),
  commonorganoraddBalance: (params) => http.post('/common/organ/addBalance', params),

  commonprescriptionlistByType: (params) => http.post('/common/prescription/listByType', params),

  commonfinancestatistics: (params) => http.post('/common/finance/statistics', params),

  commonordercopy: (params) => http.post('/common/order/copy', params),
  commonorderaudit: (params) => http.post('/common/order/audit', params),
  commonordermakeup: (params) => http.post('/common/order/makeup', params),
  commonordercheck: (params) => http.post('/common/order/check', params),
  commonorderdelivery: (params) => http.post('/common/order/delivery', params),
  commonorderreceive: (params) => http.post('/common/order/receive', params),
  commonordercancel: (params) => http.post('/common/order/cancel', params),
  //物流
  commonorderexpressList: (params) => http.post(`common/order/expressList`, params),
  commonorderexpressexpressDetails: (params) => http.post(`common/order/expressDetails`, params),
  commonorderprintExpress: (params) => http.post(`common/order/printExpress`, params),
  commonorderdeleteExpress: (params) => http.post(`common/order/deleteExpress`, params),

  adminaccountfeedback: (params) => http.post(`admin/account/feedback`, params),

  orderdrafts: (params) => http.post(`common/order/drafts`, params),
  orderuseDraft: (params) => http.post(`common/order/useDraft`, params),
  ordersaveDraft: (params) => http.post(`common/order/saveDraft`, params),
  orderdeleteDraft: (params) => http.post(`common/order/deleteDraft`, params),

  commonnoticecount: (params) => http.post(`common/notice/count`, params),
  commonnoticedetails: (params) => http.post(`common/notice/details`, params),
  commonnoticelist: (params) => http.post(`common/notice/list`, params),

  //模块接口
  ...adminApi.default,
  ...regionApi.default,
  ...centerApi.default,
  ...agentApi.default,
  ...organApi.default,
}
