<script setup lang="ts">
import { computed, provide, reactive } from 'vue'
import { useAppStore } from '@/store/modules/app'
import { ConfigGlobal } from '@/components/ConfigGlobal'
import { useDesign } from '@/hooks/web/useDesign'
import { ElMessage, ElNotification } from 'element-plus'
import * as util from '@/utils/util'
import { Dayjs } from 'dayjs'
import { useRoute, useRouter } from 'vue-router'
import api from '@/api'
import enums from '@/enum/index.js'

const { getPrefixCls } = useDesign()

const prefixCls = getPrefixCls('app')

const appStore = useAppStore()

const currentSize = computed(() => appStore.getCurrentSize)

const greyMode = computed(() => appStore.getGreyMode)

const btnconfig = reactive({
  autoInsertSpace: true,
})
appStore.initTheme()
// 路由
const route = useRoute()
const router = useRouter()
//挂载全局变量
const $app = reactive({
  route,
  router,
  api,
  dict: enums,
  $utils: util,
  $dayjs: Dayjs,
  $toast: ElMessage,
  $confirm: ElNotification,
  $formatDate: util.formatDate
})

provide('$app', $app)

window.vm = $app
</script>

<template>
  <ConfigGlobal :size="currentSize" :button="btnconfig">
    <RouterView :class="greyMode ? `${prefixCls}-grey-mode` : ''" />
  </ConfigGlobal>
</template>

<style lang="less">
@prefix-cls: ~'@{namespace}-app';

.size {
  width: 100%;
  height: 100%;
}

html,
body {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  .size;

  #app {
    .size;
  }
}

.@{prefix-cls}-grey-mode {
  filter: grayscale(100%);
}
</style>
