import http from '../request.ts'

export default {

  //中心列表
  regioncentrallist: (params) => http.post('region/central/list', params),
  regioncentralcreate: (params) => http.post('region/central/create', params),
  regioncentrallocked: (params) => http.post('region/central/locked', params),
  
  regioncentralapplies: (params) => http.post('region/central/applies', params),
  regioncentralupdate: (params) => http.post('region/central/update', params),
  regioncentralreapply: (params) => http.post('region/central/reapply', params),
  
}