import { Layout, getParentLayout } from '@/utils/routerHelper'

export const asyncRouterMap = [
  //业务经理管理
  {
    path: '/agency-manage',
    component: Layout,
    redirect: '/agency-manage/agency-list',
    name: 'AgencyManage',
    meta: {
      title: '业务经理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_CENTRAL_MANAGER']
    },
    children: [
      {
        path: 'agency-list',
        component: () => import('@/views/centerModule/AgencyManage/AgencyList/AgencyList.vue'),
        name: 'AgencyList',
        meta: {
          title: '业务经理列表',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
      {
        path: 'agency-audit',
        component: () => import('@/views/centerModule/AgencyManage/AgencyAudit/AgencyAudit.vue'),
        name: 'AgencyAudit',
        meta: {
          title: '业务经理审核记录',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
      // {
      //   path: 'agency-logs',
      //   component: () => import('@/views/centerModule/AgencyManage/AgencyLogs/AgencyLogs.vue'),
      //   name: 'AgencyLogs',
      //   meta: {
      //     title: '修改日志',
      //     permission: ['ROLE_CENTRAL_MANAGER']
      //   }
      // }
    ]
  },
  //用户管理
  {
    path: '/system-view',
    component: Layout,
    redirect: '/system-view/user-list',
    name: 'SystemView',
    meta: {
      title: '用户管理',
      icon: 'ri:function-fill',
      permission: ['ROLE_CENTRAL_MANAGER']
    },
    children: [
      {
        path: 'user-list',
        component: () => import('@/views/centerModule/SystemView/UserList/UserList.vue'),
        name: 'UserList',
        meta: {
          icon: 'ri:function-fill',
          title: '员工列表',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      }
    ]
  },
  //审方复核
  {
    path: '/examine-manage',
    component: Layout,
    redirect: '/examine-manage/copy-person',
    name: 'ExamineManage',
    meta: {
      title: '审方复核',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_CENTRAL_MANAGER']
    },
    children: [
      {
        path: 'copy-person',
        component: () => import('@/views/centerModule/ExamineManage/CopyPerson/CopyPerson.vue'),
        name: 'CopyPerson',
        meta: {
          title: '抄方人员',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
      {
        path: 'examine-person',
        component: () => import('@/views/centerModule/ExamineManage/ExaminePerson/ExaminePerson.vue'),
        name: 'ExaminePerson',
        meta: {
          title: '审方人员',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
      {
        path: 'deploy-person',
        component: () => import('@/views/centerModule/ExamineManage/DeployPerson/DeployPerson.vue'),
        name: 'DeployPerson',
        meta: {
          title: '调配人员',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
      {
        path: 'recheck-person',
        component: () => import('@/views/centerModule/ExamineManage/RecheckPerson/RecheckPerson.vue'),
        name: 'RecheckPerson',
        meta: {
          title: '复核人员',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      }
    ]
  },
  
  //颗粒库存管理
  {
    path: '/pellet-manage',
    component: Layout,
    redirect: '/pellet-manage/pellet-information',
    name: 'PelletManage',
    meta: {
      title: '颗粒库存管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
    },
    children: [
      {
        path: 'pellet-information',
        component: () => import('@/views/centerModule/PelletManage/PelletInformation/PelletInformation.vue'),
        name: 'PelletInformation',
        meta: {
          title: '颗粒信息',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'pellet-inventory',
        component: () => import('@/views/centerModule/PelletManage/PelletInventor/PelletInventor.vue'),
        name: 'PelletInventor',
        meta: {
          title: '颗粒库存',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'pellet-additional',
        component: () => import('@/views/centerModule/PelletManage/PelletAdditional/PelletAdditional.vue'),
        name: 'PelletAdditional',
        meta: {
          title: '额外库存',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'pellet-warning',
        component: () => import('@/views/centerModule/PelletManage/PelletWarning/PelletWarning.vue'),
        name: 'PelletWarning',
        meta: {
          title: '库存预警',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'intelligent-pellet',
        component: () => import('@/views/centerModule/PelletManage/IntelligentPellet/IntelligentPellet.vue'),
        name: 'IntelligentPellet',
        meta: {
          title: '智能备药',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'pellet-expired',
        component: () => import('@/views/centerModule/PelletManage/PelletExpired/PelletExpired.vue'),
        name: 'PelletExpired',
        meta: {
          title: '过期列表',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'pellet-logs',
        component: () => import('@/views/centerModule/PelletManage/PelletLogs/PelletLogs.vue'),
        name: 'PelletLogs',
        meta: {
          title: '操作日志',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      }
    ]
  },
  //饮片库存管理
  {
    path: '/drink-manage',
    component: Layout,
    redirect: '/drink-manage/drink-information',
    name: 'DrinkManage',
    meta: {
      title: '饮片库存管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
    },
    children: [
      {
        path: 'drink-information',
        component: () => import('@/views/centerModule/DrinkManage/DrinkInformation/DrinkInformation.vue'),
        name: 'DrinkInformation',
        meta: {
          title: '饮片信息',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'drink-inventory',
        component: () => import('@/views/centerModule/DrinkManage/DrinkInventor/DrinkInventor.vue'),
        name: 'DrinkInventor',
        meta: {
          title: '饮片库存',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'drink-additional',
        component: () => import('@/views/centerModule/DrinkManage/DrinkAdditional/DrinkAdditional.vue'),
        name: 'DrinkAdditional',
        meta: {
          title: '额外库存',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      // {
      //   path: 'drink-damage',
      //   component: () => import('@/views/centerModule/DrinkManage/DrinkDamage/DrinkDamage.vue'),
      //   name: 'DrinkDamage',
      //   meta: {
      //     title: '库存报损'，
      // permission: ['ROLE_CENTRAL_MANAGER']
      //   }
      // },
      {
        path: 'drink-warning',
        component: () => import('@/views/centerModule/DrinkManage/DrinkWarning/DrinkWarning.vue'),
        name: 'DrinkWarning',
        meta: {
          title: '库存预警',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'intelligent-Drink',
        component: () => import('@/views/centerModule/DrinkManage/IntelligentDrink/IntelligentDrink.vue'),
        name: 'IntelligentDrink',
        meta: {
          title: '智能备药',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'drink-expired',
        component: () => import('@/views/centerModule/DrinkManage/DrinkExpired/DrinkExpired.vue'),
        name: 'DrinkExpired',
        meta: {
          title: '过期列表',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'drink-logs',
        component: () => import('@/views/centerModule/DrinkManage/DrinkLogs/DrinkLogs.vue'),
        name: 'DrinkLogs',
        meta: {
          title: '操作日志',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      }
    ]
  },
  //订单管理
  {
    path: '/order-manage',
    component: Layout,
    redirect: '/order-manage/order-list',
    name: 'OrderManage',
    meta: {
      title: '订单管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_COPY_PRESCRIPT', 'ROLE_CENTRAL_CHECK', 'ROLE_CENTRAL_AUDIT_PRESCRIPT', 'ROLE_CENTRAL_PREPARATION', 'ROLE_CENTRAL_EXPRESS']
    },
    children: [
      {
        path: 'order-list',
        component: () => import('@/views/centerModule/OrderManage/OrderList/OrderList.vue'),
        name: 'OrderList',
        meta: {
          title: '订单列表',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_COPY_PRESCRIPT', 'ROLE_CENTRAL_CHECK', 'ROLE_CENTRAL_AUDIT_PRESCRIPT', 'ROLE_CENTRAL_PREPARATION', 'ROLE_CENTRAL_EXPRESS']
        }
      },
      {
        path: 'express-order',
        component: () => import('@/views/centerModule/OrderManage/ExpressOrder/ExpressOrder.vue'),
        name: 'ExpressOrder',
        meta: {
          title: '快递订单',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_EXPRESS']
        }
      }
    ]
  },
  // 统计管理
  {
    path: '/statistics-manage',
    component: Layout,
    redirect: '/statistics-manage/statistics-order',
    name: 'StatisticsManage',
    meta: {
      title: '统计管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
    },
    children: [
      //暂无需求
      // {
      //   path: 'statistics-order',
      //   component: () => import('@/views/centerModule/StatisticsManage/StatisticsOrder/StatisticsOrder.vue'),
      //   name: 'StatisticsOrder',
      //   meta: {
      //     title: '统计订单',
      //     permission: ['ROLE_CENTRAL_MANAGER']
      //   }
      // },
      {
        path: 'medicine-statistics',
        component: () =>
          import('@/views/centerModule/StatisticsManage/MedicineStatistics/MedicineStatistics.vue'),
        name: 'MedicineStatistics',
        meta: {
          title: '药材统计',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'medicine-detail',
        component: () => import('@/views/centerModule/StatisticsManage/MedicineDetail/MedicineDetail.vue'),
        name: 'MedicineDetail',
        meta: {
          title: '药材明细',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_STOCK']
        }
      },
      {
        path: 'sales-fluctuation',
        component: () => import('@/views/centerModule/StatisticsManage/SalesFluctuation/SalesFluctuation.vue'),
        name: 'SalesFluctuation',
        meta: {
          title: '销售波动',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
      {
        path: 'staff-preparation',
        component: () => import('@/views/centerModule/StatisticsManage/StaffPreparation/StaffPreparation.vue'),
        name: 'StaffPreparation',
        meta: {
          title: '职员调剂',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
      {
        path: 'sell-statistics',
        component: () => import('@/views/centerModule/StatisticsManage/SellStatistics/SellStatistics.vue'),
        name: 'SellStatistics',
        meta: {
          title: '畅销滞销',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
    ]
  },
  {
    path: '/qrcode-manage',
    component: Layout,
    redirect: '/qrcode-manage/finance-list',
    name: 'qrcodeManage',
    meta: {
      title: '财务管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_FINANCE'],
    },
    children: [
      {
        path: 'finance-list',
        component: () => import('@/views/centerModule/FinanceView/finance/list.vue'),
        name: 'FinanceList',
        meta: {
          title: '机构余额',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_FINANCE']
        }
      },
      {
        path: 'finance-overview',
        component: () => import('@/views/centerModule/FinanceView/overview/list.vue'),
        name: 'FinanceOverview',
        meta: {
          title: '财务概况',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_FINANCE']
        }
      },
      {
        path: 'finance-logs',
        component: () => import('@/views/centerModule/FinanceView/logs/list.vue'),
        name: 'FinanceLogs',
        meta: {
          title: '财务日志',
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_FINANCE']
        }
      },
      {
        path: 'payment-qrcode',
        component: () => import('@/views/centerModule/baseConfig/payment/qrcode.vue'),
        name: 'paymentQrcode',
        meta: {
          title: '收款码设置',
          permission: ['ROLE_CENTRAL_MANAGER']
        }
      },
    ]
  },
  //个人中心
  {
    path: '/information-view',
    component: Layout,
    redirect: '/information-view/information',
    name: 'InformationView',
    meta: {
      title: '个人中心',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_COPY_PRESCRIPT', 'ROLE_CENTRAL_CHECK', 'ROLE_CENTRAL_AUDIT_PRESCRIPT', 'ROLE_CENTRAL_PREPARATION', 'ROLE_CENTRAL_FINANCE', 'ROLE_CENTRAL_STOCK']
    },
    children: [
      {
        path: 'information',
        component: () => import('@/views/centerModule/information/information.vue'),
        name: 'Information',
        meta: {
          title: '个人中心',
          icon: 'ri:function-fill',
          alwaysShow: false,
          permission: ['ROLE_CENTRAL_MANAGER', 'ROLE_CENTRAL_COPY_PRESCRIPT', 'ROLE_CENTRAL_CHECK', 'ROLE_CENTRAL_AUDIT_PRESCRIPT', 'ROLE_CENTRAL_PREPARATION', 'ROLE_CENTRAL_FINANCE', 'ROLE_CENTRAL_STOCK']
        }
      }
    ]
  },
]