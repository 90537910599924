import http from '../request.ts'

export default {
  //中心列表
  regioncentrallist: (params) => http.post('region/central/list', params),
  regioncentralcreate: (params) => http.post('region/central/create', params),
  regioncentrallocked: (params) => http.post('region/central/locked', params),
  
  regioncentralapplies: (params) => http.post('region/central/applies', params),
  regioncentralupdate: (params) => http.post('region/central/update', params),

  organaccountlist: (params) => http.post('organ/account/list', params),
  organaccountsave: (params) => http.post('organ/account/save', params),
  organaccountreapply: (params) => http.post('organ/account/reapply', params),
  organaccountapplies: (params) => http.post('organ/account/applies', params),
  organaccountdelete: (params) => http.post('organ/account/delete', params),

  organdepartmentsave: (params) => http.post('organ/department/save', params),
  organdepartmentlist: (params) => http.post('organ/department/list', params),
  organdepartmentdelete: (params) => http.post('organ/department/delete', params),

  organprescriptionTypesave: (params) => http.post('organ/prescriptionType/save', params),
  organprescriptionTypelist: (params) => http.post('organ/prescriptionType/list', params),
  organprescriptionTypedelete: (params) => http.post('organ/prescriptionType/delete', params),
  
  organdoctorAdvicelist: (params) => http.post('organ/doctorAdvice/list', params),
  organdoctorAdvicesave: (params) => http.post('organ/doctorAdvice/save', params),
  organdoctorAdvicedelete: (params) => http.post('organ/doctorAdvice/delete', params),

  organaddresslist: (params) => http.post('organ/address/list', params),
  organaddresssave: (params) => http.post('organ/address/save', params),
  organaddressdelete: (params) => http.post('organ/address/delete', params),
  
  organconfigget: (params) => http.post('organ/config/get', params),
  organconfigsave: (params) => http.post('organ/config/save', params),

  
  organstatisticsorder: (params) => http.post('organ/statistics/order', params),
  
}