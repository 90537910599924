import { Layout, getParentLayout } from '@/utils/routerHelper'

export const asyncRouterMap = [
  {
    path: '/agent-manage',
    component: Layout,
    redirect: '/agent-manage/agent-list',
    name: 'agentManage',
    meta: {
      title: '机构管理',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
    },
    children: [
      {
        path: 'agent-list',
        component: () => import('@/views/organModule/agent/list.vue'),
        name: 'AgentList',
        meta: {
          title: '人员管理',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      },
      {
        path: 'applies-list',
        component: () => import('@/views/organModule/applies/list.vue'),
        name: 'AppliesList',
        meta: {
          title: '医生审核记录',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      },
      {
        path: 'department-list',
        component: () => import('@/views/organModule/department/list.vue'),
        name: 'DepartmenttList',
        meta: {
          title: '科室管理',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      },
    ]
  },
  // 处方管理
  {
    path: '/prescription-manage',
    component: Layout,
    redirect: '/prescription-manage/common-prescript',
    name: 'PrescriptionManage',
    meta: {
      title: '处方管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
    },
    children: [
      {
        path: 'common-prescript',
        component: () => import('@/views/organModule/PrescriptionManage/CommonPrescript/CommonPrescript.vue'),
        name: 'CommonPrescript',
        meta: {
          title: '常用处方',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      },
      {
        path: 'prescript-type',
        component: () => import('@/views/organModule/PrescriptionManage/PrescriptType/PrescriptType.vue'),
        name: 'PrescriptType',
        meta: {
          title: '处方类型',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      },
      {
        path: 'prescribed-prescript',
        component: () =>
          import('@/views/organModule/PrescriptionManage/PrescribedPrescript/PrescribedPrescript.vue'),
        name: 'PrescribedPrescript',
        meta: {
          title: '医嘱设置',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      }
    ]
  },
  {
    path: '/seting-manage',
    component: Layout,
    redirect: '/seting-manage/seting-list',
    name: 'setingManage',
    meta: {
      title: '机构设置',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
    },
    children: [
      {
        path: 'seting-list',
        component: () => import('@/views/organModule/address/list.vue'),
        name: 'addressList',
        meta: {
          title: '快递设置',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      },
      {
        path: 'config-list',
        component: () => import('@/views/organModule/config/list.vue'),
        name: 'configList',
        meta: {
          title: '功能设置',
          permission: ['ROLE_ORGAN_MANAGER']
        }
      },
      {
        path: 'payment-qrcode',
        component: () => import('@/views/adminModule/organModule/payment/qrcode.vue'),
        name: 'paymentQrcode',
        meta: {
          title: '功能设置',
          permission: ['ROLE_SPLIT_ORDER']
        }
      },
    ]
  },
  {
    path: '/order-manage',
    component: Layout,
    redirect: '/order-manage/order-list',
    name: 'orderManage',
    meta: {
      title: '订单信息',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
    },
    children: [
      {
        path: 'order-list',
        component: () => import('@/views/organModule/order/list.vue'),
        name: 'orderList',
        meta: {
          title: '订单信息',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      },
      {
        path: 'prescription-place-edit',
        component: () => import('@/views/organModule/placeEdit/list.vue'),
        name: 'PrescriptionPlaceEdit',
        meta: {
          hidden:true,
          canTo:true,
          title: '修改订单',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      }
    ]
  },
  {
    path: '/statistics-manage',
    component: Layout,
    redirect: '/statistics-manage/statistics-order',
    name: 'statisticsManage',
    meta: {
      title: '统计管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
    },
    children: [
      {
        path: 'statistics-order',
        component: () => import('@/views/organModule/StatisticsOrder/StatisticsOrder.vue'),
        name: 'statisticsOrder',
        meta: {
          title: '订单统计',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_SPLIT_ORDER']
        }
      }
    ]
  },
  {
    path: '/finance-view',
    component: Layout,
    redirect: '/finance-view/finance-list',
    name: 'FinanceView',
    meta: {
      title: '财务管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ORGAN_MANAGER','ROLE_ORGAN_FINANCE','ROLE_SPLIT_ORDER']
    },
    children: [
      {
        path: 'finance-list',
        component: () => import('@/views/organModule/finance/list.vue'),
        name: 'FinanceList',
        meta: {
          title: '机构余额',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_ORGAN_FINANCE','ROLE_SPLIT_ORDER']
        }
      },
      {
        path: 'finance-logs',
        component: () => import('@/views/organModule/logs/list.vue'),
        name: 'FinanceLogs',
        meta: {
          title: '财务日志',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_ORGAN_FINANCE','ROLE_SPLIT_ORDER']
        }
      },
      {
        path: 'finance-overview',
        component: () => import('@/views/organModule/overview/list.vue'),
        name: 'FinanceOverview',
        meta: {
          title: '财务概况',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_ORGAN_FINANCE','ROLE_SPLIT_ORDER']
        }
      },
    ]
  },
  {
    path: '/information-view',
    component: Layout,
    redirect: '/information-view/information',
    name: 'InformationView',
    meta: {
      title: '个人中心',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_ORGAN_MANAGER','ROLE_ORGAN_FINANCE','ROLE_SPLIT_ORDER']
    },
    children: [
      {
        path: 'information',
        component: () => import('@/views/organModule/information/information.vue'),
        name: 'Information',
        meta: {
          title: '个人中心',
          icon: 'ri:function-fill',
          permission: ['ROLE_ORGAN_MANAGER','ROLE_ORGAN_FINANCE','ROLE_SPLIT_ORDER']
        }
      }
    ]
  },
]