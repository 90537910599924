import http from '../request.ts'

export default {
  //个人中心
  adminaccountloginLogs: (params) => http.post('/admin/account/loginLogs', params),
  //系统配置
  adminconfiggetConfig: (params) => http.post(`/admin/config/getConfig`, params),
  adminconfigsaveConfig: (params) => http.post(`/admin/config/saveConfig`, params),
  //系统管理
  adminconfigfeedbacks: (params) => http.post(`/admin/config/feedbacks`, params),
  adminaccountlist: (params) => http.post(`/admin/account/list`, params),
  adminaccountlocked: (params) => http.post(`/admin/account/locked`, params),
  adminaccountsave: (params) => http.post(`/admin/account/save`, params),
  adminaccountdelete: (params) => http.post(`/admin/account/delete`, params),
  //厂家信息
  adminmanufacturerlist: (params) => http.post(`/admin/manufacturer/list`, params),
  adminmanufacturersave: (params) => http.post(`/admin/manufacturer/save`, params),
  adminmanufacturerdelete: (params) => http.post(`/admin/manufacturer/delete`, params),
  //颗粒信息
  admingranularlist: (params) => http.post(`/admin/granular/list`, params),
  admingranularsave: (params) => http.post(`/admin/granular/save`, params),
  admingranulardelete: (params) => http.post(`/admin/granular/delete`, params),
  //饮片信息
  admintabletlist: (params) => http.post(`/admin/tablet/list`, params),
  admintabletsave: (params) => http.post(`/admin/tablet/save`, params),
  admintabletdelete: (params) => http.post(`/admin/tablet/delete`, params),
  //审核管理
  admincentralapplies: (params) => http.post(`/admin/central/applies`, params),
  admincentralauditApply: (params) => http.post(`/admin/central/auditApply`, params),
  adminorganapplies: (params) => http.post(`/admin/organ/applies`, params),
  adminorganauditApply: (params) => http.post(`/admin/organ/auditApply`, params),
  adminorgandoctorApplies: (params) => http.post(`/admin/organ/doctorApplies`, params),
  adminorganauditDoctor: (params) => http.post(`/admin/organ/auditDoctor`, params),
  adminagentapplies: (params) => http.post(`/admin/agent/applies`, params),
  adminagentauditApply: (params) => http.post(`/admin/agent/auditApply`, params),
  adminagentlist: (params) => http.post(`/admin/agent/list`, params),
  //大区
  adminregionlist: (params) => http.post(`/admin/region/list`, params),
  adminregionsave: (params) => http.post(`/admin/region/save`, params),
  //公告
  adminnoticesave: (params) => http.post(`/admin/notice/save`, params),
  adminnoticedelete: (params) => http.post(`/admin/notice/delete`, params),
  //处方管理
  adminprescriptionTypesave: (params) => http.post(`/admin/prescriptionType/save`, params),
  adminprescriptionTypelist: (params) => http.post(`/admin/prescriptionType/list`, params),
  adminprescriptionTypedelete: (params) => http.post(`/admin/prescriptionType/delete`, params),


  adminorganupdate: (params) => http.post(`/admin/organ/update`, params),
  adminorgancreate: (params) => http.post(`/admin/organ/create`, params),
  adminorganlist: (params) => http.post(`/admin/organ/list`, params),

  admincentrallist: (params) => http.post(`/admin/central/list`, params),

  adminorgansaveDistributionConfig: (params) => http.post(`/admin/organ/saveDistributionConfig`, params),
  adminorgangetDistributionConfig: (params) => http.post(`/admin/organ/getDistributionConfig`, params),

  admincentralsetArea: (params) => http.post(`/admin/central/setArea`, params),
}
