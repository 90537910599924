import { Layout, getParentLayout } from '@/utils/routerHelper'

export const asyncRouterMap = [
  // 处方管理
  {
    path: '/prescription-manage',
    component: Layout,
    redirect: '/prescription-manage/common-prescript',
    name: 'PrescriptionManage',
    meta: {
      title: '处方管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ORGAN_DOCTOR']
    },
    children: [
      {
        path: 'common-prescript',
        component: () => import('@/views/doctorModule/PrescriptionManage/CommonPrescript/list.vue'),
        name: 'CommonPrescript',
        meta: {
          title: '常用处方',
          permission: ['ROLE_ORGAN_DOCTOR']
        }
      },
      {
        path: 'prescribed-prescript',
        component: () => import('@/views/doctorModule/PrescriptionManage/PrescribedPrescript/list.vue'),
        name: 'PrescribedPrescript',
        meta: {
          title: '医嘱设置',
          permission: ['ROLE_ORGAN_DOCTOR']
        }
      },
      {
        path: 'prescription-place',
        component: () => import('@/views/doctorModule/PrescriptionManage/place/list.vue'),
        name: 'PrescriptionPlace',
        meta: {
          title: '接诊开方',
          permission: ['ROLE_ORGAN_DOCTOR']
        }
      },
      {
        path: 'prescription-place-edit',
        component: () => import('@/views/doctorModule/PrescriptionManage/placeEdit/list.vue'),
        name: 'PrescriptionPlaceEdit',
        meta: {
          hidden:true,
          canTo:true,
          title: '修改订单',
          permission: ['ROLE_ORGAN_DOCTOR']
        }
      }
    ]
  },
  {
    path: '/order-manage',
    component: Layout,
    redirect: '/order-manage/order-list',
    name: 'orderManage',
    meta: {
      title: '订单信息',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ORGAN_DOCTOR']
    },
    children: [
      {
        path: 'order-list',
        component: () => import('@/views/doctorModule/order/list.vue'),
        name: 'orderList',
        meta: {
          title: '订单信息',
          permission: ['ROLE_ORGAN_DOCTOR']
        }
      }
    ]
  },
  {
    path: '/information-view',
    component: Layout,
    redirect: '/information-view/information',
    name: 'InformationView',
    meta: {
      title: '个人中心',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_ORGAN_DOCTOR']
    },
    children: [
      {
        path: 'information',
        component: () => import('@/views/doctorModule/information/information.vue'),
        name: 'Information',
        meta: {
          title: '个人中心',
          icon: 'ri:function-fill',
          permission: ['ROLE_ORGAN_DOCTOR']
        }
      }
    ]
  },
]