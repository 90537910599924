import { Layout, getParentLayout } from '@/utils/routerHelper'

export const asyncRouterMap = [
  {
    path: '/agent-manage',
    component: Layout,
    redirect: '/agent-manage/agent-list',
    name: 'agentManage',
    meta: {
      title: '机构列表',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_AGENT_MANAGER']
    },
    children: [
      {
        path: 'agent-list',
        component: () => import('@/views/agentModule/agent/list.vue'),
        name: 'AgentList',
        meta: {
          title: '机构列表',
          permission: ['ROLE_AGENT_MANAGER']
        }
      },
      {
        path: 'applies-list',
        component: () => import('@/views/agentModule/applies/list.vue'),
        name: 'AppliesList',
        meta: {
          title: '机构审核记录',
          permission: ['ROLE_AGENT_MANAGER']
        }
      }
    ]
  },
  {
    path: '/order-manage',
    component: Layout,
    redirect: '/order-manage/order-list',
    name: 'orderManage',
    meta: {
      title: '订单管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_AGENT_MANAGER']
    },
    children: [
      {
        path: 'order-list',
        component: () => import('@/views/agentModule/order/list.vue'),
        name: 'orderList',
        meta: {
          title: '订单信息',
          permission: ['ROLE_AGENT_MANAGER']
        }
      }
    ]
  },
  {
    path: '/finance-manage',
    component: Layout,
    redirect: '/finance-manage/finance-list',
    name: 'financeManage',
    meta: {
      title: '财务管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_AGENT_MANAGER']
    },
    children: [
      {
        path: 'finance-list',
        component: () => import('@/views/agentModule/finance/list.vue'),
        name: 'financeList',
        meta: {
          title: '机构余额',
          permission: ['ROLE_AGENT_MANAGER']
        }
      },
      {
        path: 'logs-list',
        component: () => import('@/views/agentModule/logs/list.vue'),
        name: 'logsList',
        meta: {
          title: '财务日志',
          permission: ['ROLE_AGENT_MANAGER']
        }
      }
    ]
  },
  {
    path: '/information-view',
    component: Layout,
    redirect: '/information-view/information',
    name: 'InformationView',
    meta: {
      title: '个人中心',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_AGENT_MANAGER']
    },
    children: [
      {
        path: 'information',
        component: () => import('@/views/agentModule/information/information.vue'),
        name: 'Information',
        meta: {
          title: '个人中心',
          icon: 'ri:function-fill',
          permission: ['ROLE_AGENT_MANAGER']
        }
      }
    ]
  },
]