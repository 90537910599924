import { filterEnum, filterCityCode } from '@/utils/util'
function ftLabelValue(value, dictName) {
  const dict = this[dictName]
  const item = dict.find(item => item.value === value)
  return item ? item.label : ''
}

const dict = ref({
  ftLabelValue,
  filterEnum,
  formatter: (row, column) => {
    return row[column.property] || '/'
  },
  formatDict: (row, column) => {
    if (!row || !column) return '/';
    const enumData = dict.value[column.property]
    if (enumData) {
      const item = row[column.property];
      return filterEnum(item, enumData)
    } else {
      return '/';
    }
  },
  formatAddress: (row, column) => {
    if (!row || !column) return '/';
    const { contactProvince, contactCity, contactDistrict } = row;
    let address = filterCityCode({
      province: contactProvince, city: contactCity, district: contactDistrict
    })
    return address.join(',');
  },
  //手机号脱敏
  maskPhone: (value) => {
    return value ? value.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') : '-';
  },
  role: [
    { label: '超级管理员', value: 'ROLE_ADMIN', lv: 0, lvname: '平台' },
    { label: '普通管理员', value: 'ROLE_NORMAL_ADMIN', lv: 0, lvname: '平台' },
    { label: '财务管理', value: 'ROLE_FINANCE', lv: 0, lvname: '平台' },
    { label: '审核', value: 'ROLE_AUDITOR', lv: 0, lvname: '平台' },
    { label: '基础信息维护', value: 'ROLE_BASE_INFO', lv: 0, lvname: '平台' },
    { label: '平台分单', value: 'ROLE_SPLIT_ORDER', lv: 0, lvname: '平台' },
    { label: '大区管理员', value: 'ROLE_REGION_MANAGER', lv: 1, lvname: '大区' },
    { label: '调剂中心', value: 'ROLE_CENTRAL_MANAGER', lv: 2, lvname: '中心' },
    { label: '抄方', value: 'ROLE_CENTRAL_COPY_PRESCRIPT', lv: 2, lvname: '中心' },
    { label: '审方', value: 'ROLE_CENTRAL_AUDIT_PRESCRIPT', lv: 2, lvname: '中心' },
    { label: '调剂', value: 'ROLE_CENTRAL_PREPARATION', lv: 2, lvname: '中心' },
    { label: '复核', value: 'ROLE_CENTRAL_CHECK', lv: 2, lvname: '中心' },
    { label: '快递', value: 'ROLE_CENTRAL_EXPRESS', lv: 2, lvname: '中心' },
    { label: '财务管理', value: 'ROLE_CENTRAL_FINANCE', lv: 2, lvname: '中心' },
    { label: '药品管理', value: 'ROLE_CENTRAL_STOCK', lv: 2, lvname: '中心' },
    { label: '业务经理', value: 'ROLE_AGENT_MANAGER', lv: 3, lvname: '代理商' },
    { label: '机构主账号', value: 'ROLE_ORGAN_MANAGER', lv: 4, lvname: '机构' },
    { label: '医生', value: 'ROLE_ORGAN_DOCTOR', lv: 4, lvname: '机构' },
    { label: '财务管理', value: 'ROLE_ORGAN_FINANCE', lv: 4, lvname: '机构' },
  ],
  auditResult: [
    { label: '待审核', value: 1 },
    { label: '已通过', value: 2 },
    { label: '未通过', value: 3 },
  ],
  
  locked: [
    { label: '正常', value: false },
    { label: '禁用', value: true },
  ],
  userLocked: [
    { label: '启用', value: false },
    { label: '禁用', value: true },
  ],
  orderStatus: [
    // { label: '草稿', value: 'DRAFT', remoke: '预留' },
    { label: '待抄方', value: 'ORDERED', remoke: '待抄方' },
    { label: '待审方', value: 'COPIED', remoke: '待审方' },
    { label: '抄方 退回', value: 'COPY_REJECT' },
    { label: '待调剂', value: 'AUDITED', remoke: '待调剂' },
    { label: '审方 退回', value: 'AUDIT_REJECT' },
    { label: '调剂中', value: 'MAKEUP_ING' },
    { label: '调剂失败', value: 'MAKEUP_FAILED' },
    { label: '待复核', value: 'MAKEUP_FINISH', remoke: '待复核' },
    { label: '待发货', value: 'CHECKED' },
    { label: '已发货', value: 'DELIVERED' },
    { label: '已收货', value: 'RECEIVED' },
    { label: '订单取消', value: 'CANCELLED' },
  ],
  patientGender: [
    { label: '男', value: 0 },
    { label: '女', value: 1 },
  ],
  managerGender: [
    { label: '男', value: 0 },
    { label: '女', value: 1 },
  ],
  gender: [
    { label: '男', value: 0 },
    { label: '女', value: 1 },
  ],
  active: [
    { label: '启用', value: true },
    { label: '禁用', value: false },
  ],
  booleans: [
    { label: '是', value: true },
    { label: '否', value: false },
  ],
  applyType: [
    { label: '待审核', value: 1 },
    { label: '已通过', value: 2 },
    { label: '未通过', value: 3 }
  ],
  addressType: [
    { label: '发件人', value: 1 },
    { label: '退货地址', value: 2 },
  ],
  defaultAddress: [
    { label: '是', value: true },
    { label: '否', value: false },
  ],
  addressLocked: [
    { label: '禁用', value: true },
    { label: '启用', value: false },
  ],
  financeLocked: [
    { label: '锁定', value: true },
    { label: '正常', value: false },
  ],
  walletLocked: [
    { label: '锁定', value: true },
    { label: '正常', value: false },
  ],
  makeUpPrescriptionFeeType: [
    { label: '单', value: 1 },
    { label: '剂', value: 2 },
    { label: '天', value: 3 }
  ],
  standardType: [
    { label: '国标', value: 'NATION' },
    { label: '省标', value: 'PROVINCE' }
  ],
  logType: [
    { label: '机构下单-调剂', value: 'ORDERED' },
    { label: '平台增加', value:  'ADMIN_ADD' },
    { label: '平台减少', value:  'ADMIN_SUB' },
    { label: '机构下单-回退', value:  'COPY_REJECT' },
    { label: '机构下单-回退', value:  'AUDIT_REJECT' },
    { label: '机构下单-回退', value:  'CANCELLED' },
  ],
  financeLogType: [
    { label: '机构下单-调剂', value: 'ORDERED' },
    { label: '平台增加', value:  'ADMIN_ADD' },
    { label: '平台减少', value:  'ADMIN_SUB' },
    { label: '机构下单-回退', value:  'CANCELLED' },
  ],
  recordType: [
    { label: '入库', value: 1 },
    { label: '出库', value: 2 },
    { label: '调剂', value: 3 },
    { label: '退货', value: 4 },
  ],
  prescriptionType: [
    { label: '颗粒', value: 1 },
    { label: '饮片', value: 2 },
    { label: '膏方', value: 3 },
  ]
})

export default dict