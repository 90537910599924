import { Layout, getParentLayout } from '@/utils/routerHelper'

export const asyncRouterMap = [
  {
    path: '/center-manage',
    component: Layout,
    redirect: '/center-manage/center-list',
    name: 'CenterManage',
    meta: {
      title: '调剂中心管理',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_REGION_MANAGER']
    },
    children: [
      {
        path: 'center-list',
        component: () => import('@/views/regionModule/RegionManage/CenterList/CenterList.vue'),
        name: 'CenterList',
        meta: {
          icon: 'ri:function-fill',
          title: '调剂中心管理',
          permission: ['ROLE_REGION_MANAGER']
        }
      },
    ]
  },
  {
    path: '/record-manage',
    component: Layout,
    redirect: '/record-manage/record-list',
    name: 'RecordManage',
    meta: {
      title: '调剂中心审核记录',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_REGION_MANAGER']
    },
    children: [
      {
        path: 'region-record',
        component: () => import('@/views/regionModule/RegionManage/RecordList/RecordList.vue'),
        name: 'RecordList',
        meta: {
          icon: 'ri:function-fill',
          title: '调剂中心审核记录',
          permission: ['ROLE_REGION_MANAGER']
        }
      }
    ]
  },
  {
    path: '/order-manage',
    component: Layout,
    redirect: '/order-manage/order-list',
    name: 'OrderManage',
    meta: {
      title: '订单信息',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_REGION_MANAGER']
    },
    children: [
      {
        path: 'order-list',
        component: () => import('@/views/regionModule/OrderManage/OrderList/list.vue'),
        name: 'OrderList',
        meta: {
          icon: 'ri:function-fill',
          title: '订单信息',
          permission: ['ROLE_REGION_MANAGER']
        }
      }
    ]
  },
  {
    path: '/finance-view',
    component: Layout,
    redirect: '/finance-view/finance-list',
    name: 'FinanceView',
    meta: {
      title: '财务管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_REGION_MANAGER']
    },
    children: [
      {
        path: 'finance-list',
        component: () => import('@/views/regionModule/FinanceView/finance/list.vue'),
        name: 'FinanceList',
        meta: {
          title: '机构余额',
          permission: ['ROLE_REGION_MANAGER']
        }
      },
      {
        path: 'finance-log',
        component: () => import('@/views/regionModule/FinanceView/logs/list.vue'),
        name: 'FinanceLog',
        meta: {
          title: '财务日志',
          permission: ['ROLE_REGION_MANAGER']
        }
      },
    ]
  },
  {
    path: '/information-view',
    component: Layout,
    redirect: '/information-view/information',
    name: 'InformationView',
    meta: {
      title: '个人中心',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_REGION_MANAGER']
    },
    children: [
      {
        path: 'information',
        component: () => import('@/views/regionModule/SystemView/information/information.vue'),
        name: 'Information',
        meta: {
          title: '个人中心',
          icon: 'ri:function-fill',
          permission: ['ROLE_REGION_MANAGER']
        }
      }
    ]
  },
]