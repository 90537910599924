import { Layout, getParentLayout } from '@/utils/routerHelper'

export const asyncRouterMap = [
  {
    path: '/system-view',
    component: Layout,
    redirect: '/system-view/user-list',
    name: 'SystemView',
    meta: {
      title: '用户管理',
      alwaysShow: true,
      icon: 'ri:function-fill',
      permission: ['ROLE_ADMIN']
    },
    children: [
      {
        path: 'user-list',
        component: () => import('@/views/adminModule/SystemView/UserList/UserList.vue'),
        name: 'UserList',
        meta: {
          icon: 'ri:function-fill',
          title: '用户列表',
          permission: ['ROLE_ADMIN']
        }
      }
    ]
  },
  {
    path: '/base-config',
    component: Layout,
    redirect: '/base-config/manufacturer-list',
    name: 'BaseConfig',
    meta: {
      title: '基础信息',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ADMIN', 'ROLE_BASE_INFO']
    },
    children: [
      {
        path: 'manufacturer-list',
        component: () => import('@/views/adminModule/baseConfig/manufacturer/list.vue'),
        name: 'manufacturerList',
        meta: {
          title: '厂家信息',
          permission: ['ROLE_ADMIN', 'ROLE_BASE_INFO']
        }
      },
      {
        path: 'granular-list',
        component: () => import('@/views/adminModule/baseConfig/granular/list.vue'),
        name: 'granularList',
        meta: {
          title: '颗粒信息',
          permission: ['ROLE_ADMIN', 'ROLE_BASE_INFO']
        }
      },
      {
        path: 'tablet-list',
        component: () => import('@/views/adminModule/baseConfig/tablet/list.vue'),
        name: 'tabletList',
        meta: {
          title: '饮片信息',
          permission: ['ROLE_ADMIN', 'ROLE_BASE_INFO']
        }
      },
      {
        path: 'payment-qrcode',
        component: () => import('@/views/adminModule/baseConfig/payment/qrcode.vue'),
        name: 'paymentQrcode',
        meta: {
          title: '收款码设置',
          permission: ['ROLE_ADMIN']
        }
      },
    ]
  },
  {
    path: '/prescription-manage',
    component: Layout,
    redirect: '/prescription-manage/common-prescript',
    name: 'PrescriptionManage',
    meta: {
      title: '处方管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ADMIN']
    },
    children: [
      {
        path: 'common-prescript',
        component: () => import('@/views/adminModule/PrescriptionManage/CommonPrescript/CommonPrescript.vue'),
        name: 'CommonPrescript',
        meta: {
          title: '经典处方',
          permission: ['ROLE_ADMIN']
        }
      },
      {
        path: 'prescript-type',
        component: () => import('@/views/adminModule/PrescriptionManage/PrescriptType/PrescriptType.vue'),
        name: 'PrescriptType',
        meta: {
          title: '处方类型',
          permission: ['ROLE_ADMIN']
        }
      }
    ]
  },
  {
    path: '/organ-manage',
    component: Layout,
    redirect: '/organ-manage/organ-agent-list',
    name: 'organManage',
    meta: {
      title: '分单管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ADMIN']
    },
    children: [
      {
        path: 'organ-agent-list',
        component: () => import('@/views/adminModule/organModule/agent/list.vue'),
        name: 'OrganAgentList',
        meta: {
          title: '平台机构',
          permission: ['ROLE_ADMIN']
        }
      }
    ]
  },
  {
    path: '/region-manage',
    component: Layout,
    redirect: '/region-manage/region-list',
    name: 'RegionManage',
    meta: {
      title: '大区管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ADMIN']
    },
    children: [
      {
        path: 'region-list',
        component: () => import('@/views/adminModule/RegionManage/region/list.vue'),
        name: 'regionList',
        meta: {
          title: '大区管理员',
          icon: 'ri:function-fill',
          permission: ['ROLE_ADMIN']
        }
      },
    ]
  },
  {
    path: '/applies-view',
    component: Layout,
    redirect: '/applies-view/admin-agent-list',
    name: 'AppliesView',
    meta: {
      title: '审核管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ADMIN', 'ROLE_AUDITOR']
    },
    children: [
      {
        path: 'admin-center-list',
        component: () => import('@/views/adminModule/adminList/CenterList/CenterList.vue'),
        name: 'AdminCenterList',
        meta: {
          title: '调剂中心列表',
          permission: ['ROLE_ADMIN', 'ROLE_AUDITOR']
        }
      },
      {
        path: 'admin-agency-list',
        component: () => import('@/views/adminModule/adminList/AgencyList/AgencyList.vue'),
        name: 'AdminAgencyList',
        meta: {
          title: '业务经理列表',
          permission: ['ROLE_ADMIN', 'ROLE_AUDITOR']
        }
      },
      {
        path: 'admin-agent-list',
        component: () => import('@/views/adminModule/adminList/agent/list.vue'),
        name: 'AdminAgentList',
        meta: {
          title: '医疗机构列表',
          permission: ['ROLE_ADMIN', 'ROLE_AUDITOR']
        }
      },
      {
        path: 'central-list',
        component: () => import('@/views/adminModule/AppliesView/central/list.vue'),
        name: 'CentralList',
        meta: {
          title: '调剂中心审核',
          permission: ['ROLE_ADMIN', 'ROLE_AUDITOR']
        }
      },
      {
        path: 'agent-list',
        component: () => import('@/views/adminModule/AppliesView/agent/list.vue'),
        name: 'AgentList',
        meta: {
          title: '业务经理审核',
          permission: ['ROLE_ADMIN', 'ROLE_AUDITOR']
        }
      },
      {
        path: 'organ-list',
        component: () => import('@/views/adminModule/AppliesView/organ/list.vue'),
        name: 'OrganList',
        meta: {
          title: '医疗机构审核',
          permission: ['ROLE_ADMIN', 'ROLE_AUDITOR']
        }
      },
      {
        path: 'doctor-list',
        component: () => import('@/views/adminModule/AppliesView/doctor/list.vue'),
        name: 'DoctorList',
        meta: {
          title: '医生信息审核',
          permission: ['ROLE_ADMIN', 'ROLE_AUDITOR']
        }
      },
    ]
  },
  {
    path: '/finance-view',
    component: Layout,
    redirect: '/finance-view/finance-list',
    name: 'FinanceView',
    meta: {
      title: '财务管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ADMIN', 'ROLE_FINANCE']
    },
    children: [
      {
        path: 'finance-list',
        component: () => import('@/views/adminModule/FinanceView/finance/list.vue'),
        name: 'FinanceList',
        meta: {
          title: '财务管理',
          permission: ['ROLE_ADMIN', 'ROLE_FINANCE']
        }
      },
      {
        path: 'finance-log',
        component: () => import('@/views/adminModule/FinanceView/finaceLog/list.vue'),
        name: 'FinanceLog',
        meta: {
          title: '财务日志',
          permission: ['ROLE_ADMIN']
        }
      },
    ]
  },
  {
    path: '/notice-view',
    component: Layout,
    redirect: '/notice-view/notice-list',
    name: 'NoticeView',
    meta: {
      title: '公告管理',
      icon: 'ri:function-fill',
      alwaysShow: true,
      permission: ['ROLE_ADMIN']
    },
    children: [
      {
        path: 'notice-list',
        component: () => import('@/views/adminModule/NoticeView/notice/list.vue'),
        name: 'Notices',
        meta: {
          title: '公告列表',
          icon: 'ri:function-fill',
          permission: ['ROLE_ADMIN']
        }
      }
    ]
  },
  {
    path: '/feedbacks-view',
    component: Layout,
    redirect: '/feedbacks-view/feedbacks-list',
    name: 'FeedbackView',
    meta: {
      title: '意见反馈',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_ADMIN']
    },
    children: [
      {
        path: 'feedbacks-list',
        component: () => import('@/views/adminModule/SystemView/feedbacks/feedbacks.vue'),
        name: 'FeedBacks',
        meta: {
          title: '意见反馈',
          icon: 'ri:function-fill',
          permission: ['ROLE_ADMIN']
        }
      }
    ]
  },

  {
    path: '/information-view',
    component: Layout,
    redirect: '/information-view/information',
    name: 'InformationView',
    meta: {
      title: '个人中心',
      icon: 'ri:function-fill',
      alwaysShow: false,
      permission: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_AUDITOR', 'ROLE_BASE_INFO']
    },
    children: [
      {
        path: 'information',
        component: () => import('@/views/adminModule/SystemView/information/information.vue'),
        name: 'Information',
        meta: {
          title: '个人中心',
          icon: 'ri:function-fill',
          permission: ['ROLE_ADMIN', 'ROLE_FINANCE', 'ROLE_AUDITOR', 'ROLE_BASE_INFO']
        }
      }
    ]
  },
]