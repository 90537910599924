import http from '../request.ts'

export default {

  //用户列表
  centralaccountsave: (params) => http.post('central/account/save', params),//保存账号
  centralaccountdelete: (params) => http.post('central/account/delete', params),//保存账号
  centralaccountlist: (params) => http.post('central/account/list', params),//账号列表
  centralaccountlocked: (params) => http.post('central/account/locked', params),//冻结/启用 用户
  centraltabletlist: (params) => http.post(`central/tablet/list`, params),
  centraltabletstockList: (params) => http.post(`central/tablet/stockList`, params),
  centraltabletaddStock: (params) => http.post(`central/tablet/addStock`, params),
  centraltabletupdateThreshold: (params) => http.post(`central/tablet/updateThreshold`, params),
  centraltabletShelling: (params) => http.post(`central/tablet/updateShelling`, params),

  //出库
  centraltabletsubStock: (params) => http.post(`central/tablet/subStock`, params),
  //库存预警
  centraltabletstockWarningList: (params) => http.post(`central/tablet/stockWarningList`, params),
  centraltabletusedData: (params) => http.post(`central/tablet/usedData`, params),
  centraltabletstockExpiredList: (params) => http.post(`central/tablet/stockExpiredList`, params),
  //操作日志
  centraltabletstockLogs: (params) => http.post(`central/tablet/stockLogs`, params),

  //业务经理
  centralagentcreate: (params) => http.post(`/central/agent/create`, params),
  centralagentupdate: (params) => http.post(`/central/agent/update`, params),
  centralagentreapply: (params) => http.post(`/central/agent/reapply`, params),
  centralagentlist: (params) => http.post(`/central/agent/list`, params),
  centralagentapplies: (params) => http.post(`central/agent/applies`, params),
  centralagentupdateLogs: (params) => http.post(`central/agent/updateLogs`, params),
  centralgranularlist: (params) => http.post(`central/granular/list`, params),
  centralgranularstockList: (params) => http.post(`central/granular/stockList`, params),
  centralgranularaddStock: (params) => http.post(`central/granular/addStock`, params),
  centralgranularupdateThreshold: (params) => http.post(`central/granular/updateThreshold`, params),
  centralgranularupdateShelling: (params) => http.post(`central/granular/updateShelling`, params),
  //出库
  centralgranularsubStock: (params) => http.post(`central/granular/subStock`, params),
  //库存预警
  centralgranularstockWarningList: (params) => http.post(`central/granular/stockWarningList`, params),
  centralgranularusedData: (params) => http.post(`central/granular/usedData`, params),
  centralgranularstockExpiredList: (params) => http.post(`central/granular/stockExpiredList`, params),
  //操作日志
  centralgranularstockLogs: (params) => http.post(`central/granular/stockLogs`, params),

  //统计模块
  centralstatisticssaleData: (params) => http.post(`central/statistics/saleData`, params),
  centralstatisticsmakeupData: (params) => http.post(`central/statistics/makeupData`, params),
  centralstatisticsorderTrends: (params) => http.post(`central/statistics/orderTrends`, params),
  centralstatisticsorderMedicationLogs: (params) => http.post(`central/statistics/orderMedicationLogs`, params),
  centralstatisticsmedicationLogs: (params) => http.post(`central/statistics/medicationLogs`, params),

}