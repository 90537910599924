import { Layout, getParentLayout } from '@/utils/routerHelper'
import { useI18n } from '@/hooks/web/useI18n'
import * as adminModule from './module/admin.js'
import * as regionModule from './module/region.js'
import * as agentModule from './module/agent.js'
import * as centerModule from './module/center.js'
import * as organModule from './module/organ.js'
import * as doctorModule from './module/doctor.js'

const { t } = useI18n()
export const constantRouterMap = [
  {
    path: '/',
    component: Layout,
    redirect: '/information-view/information',
    name: 'Root',
    meta: {
      hidden: true
    }
  },
  {
    path: '/redirect',
    component: Layout,
    name: 'Redirect',
    children: [
      {
        path: '/redirect/:path(.*)',
        name: 'Redirect',
        component: () => import('@/views/Redirect/Redirect.vue'),
        meta: {}
      }
    ],
    meta: {
      hidden: true,
      noTagsView: true
    }
  },
  {
    path: '/login',
    component: () => import('@/views/Login/Login.vue'),
    name: 'Login',
    meta: {
      hidden: true,
      title: t('router.login'),
      noTagsView: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/Error/404.vue'),
    name: 'NoFind',
    meta: {
      hidden: true,
      title: '404',
      noTagsView: true
    }
  }
]

export const asyncRouterMap = [
  ...adminModule.asyncRouterMap,
  ...regionModule.asyncRouterMap,
  ...centerModule.asyncRouterMap,
  ...agentModule.asyncRouterMap,
  ...organModule.asyncRouterMap,
  ...doctorModule.asyncRouterMap,
]
