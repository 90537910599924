import { isArray } from '@/utils/is'
import _round from 'lodash/round'
import Dayjs from 'dayjs'
/**
 * @description 获取localStorage
 * @param {String} key Storage名称
 * @return string
 */
export function localGet(key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    return value
  }
}

/**
 * @description 存储localStorage
 * @param {String} key Storage名称
 * @param {Any} value Storage值
 * @return void
 */
export function localSet(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

/**
 * @description 清除localStorage
 * @param {String} key Storage名称
 * @return void
 */
export function localRemove(key) {
  window.localStorage.removeItem(key)
}

/**
 * @description 清除所有localStorage
 * @return void
 */
export function localClear() {
  window.localStorage.clear()
}

export function formatDate(date, fmt = 'YYYY-MM-DD') {
  if (!date) return ''
  date = date.toString()
  const y = date.slice(0, 4)
  const m = date.slice(4, 6)
  const d = date.slice(6, 8)
  return Dayjs(`${y}-${m}-${d}`).format(fmt)
}

/**
 * @description 判断数据类型
 * @param {Any} val 需要判断类型的数据
 * @return string
 */
export function isType(val) {
  if (val === null) return 'null'
  if (typeof val !== 'object') return typeof val
  else return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase()
}

/**
 * @description 生成唯一 uuid
 * @return string
 */
export function generateUUID() {
  if (typeof crypto === 'object') {
    if (typeof crypto.randomUUID === 'function') {
      return crypto.randomUUID()
    }
    if (typeof crypto.getRandomValues === 'function' && typeof Uint8Array === 'function') {
      const callback = (c) => {
        const num = Number(c)
        return (num ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (num / 4)))).toString(
          16
        )
      }
      return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, callback)
    }
  }
  let timestamp = new Date().getTime()
  let performanceNow =
    (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let random = Math.random() * 16
    if (timestamp > 0) {
      random = (timestamp + random) % 16 | 0
      timestamp = Math.floor(timestamp / 16)
    } else {
      random = (performanceNow + random) % 16 | 0
      performanceNow = Math.floor(performanceNow / 16)
    }
    return (c === 'x' ? random : (random & 0x3) | 0x8).toString(16)
  })
}

/**
 * 判断两个对象是否相同
 * @param a 要比较的对象一
 * @param b 要比较的对象二
 * @returns 相同返回 true，反之则反
 */
export function isObjectValueEqual(a, b) {
  if (!a || !b) return false
  let aProps = Object.getOwnPropertyNames(a)
  let bProps = Object.getOwnPropertyNames(b)
  if (aProps.length != bProps.length) return false
  for (let i = 0; i < aProps.length; i++) {
    let propName = aProps[i]
    let propA = a[propName]
    let propB = b[propName]
    if (!b.hasOwnProperty(propName)) return false
    if (propA instanceof Object) {
      if (!isObjectValueEqual(propA, propB)) return false
    } else if (propA !== propB) {
      return false
    }
  }
  return true
}

/**
 * @description 生成随机数
 * @param {Number} min 最小值
 * @param {Number} max 最大值
 * @return number
 */
export function randomNum(min, max) {
  let num = Math.floor(Math.random() * (min - max) + max)
  return num
}

/**
 * @description 获取当前时间对应的提示语
 * @return string
 */
export function getTimeState() {
  // 获取当前时间
  let timeNow = new Date()
  // 获取当前小时
  let hours = timeNow.getHours()
  // 判断当前时间段
  if (hours >= 6 && hours <= 10) return `早上好 ⛅`
  if (hours >= 10 && hours <= 14) return `中午好 🌞`
  if (hours >= 14 && hours <= 18) return `下午好 🌞`
  if (hours >= 18 && hours <= 24) return `晚上好 🌛`
  if (hours >= 0 && hours <= 6) return `凌晨好 🌛`
}

/**
 * @description 获取浏览器默认语言
 * @return string
 */
export function getBrowserLang() {
  let browserLang = navigator.language ? navigator.language : navigator.browserLanguage
  let defaultBrowserLang = ''
  if (
    browserLang.toLowerCase() === 'cn' ||
    browserLang.toLowerCase() === 'zh' ||
    browserLang.toLowerCase() === 'zh-cn'
  ) {
    defaultBrowserLang = 'zh'
  } else {
    defaultBrowserLang = 'en'
  }
  return defaultBrowserLang
}

/**
 * @description 递归查询当前路由所对应的路由
 * @param {Array} menuList 所有菜单列表
 * @param {String} path 当前访问地址
 * @return array
 */
export function filterCurrentRoute(menuList, path) {
  let result = {}
  for (let item of menuList) {
    if (item.path === path) return item
    if (item.children) {
      const res = filterCurrentRoute(item.children, path)
      if (Object.keys(res).length) result = res
    }
  }
  return result
}

/**
 * @description 扁平化数组对象(主要用来处理路由菜单)
 * @param {Array} menuList 所有菜单列表
 * @return array
 */
export function getFlatArr(menuList) {
  let newMenuList = JSON.parse(JSON.stringify(menuList))
  return newMenuList.reduce((pre, current) => {
    let flatArr = [...pre, current]
    if (current.children) flatArr = [...flatArr, ...getFlatArr(current.children)]
    return flatArr
  }, [])
}

/**
 * @description 使用递归，过滤需要缓存的路由（暂时没有使用）
 * @param {Array} menuList 所有菜单列表
 * @param {Array} cacheArr 缓存的路由菜单 name ['**','**']
 * @return array
 * */
export function getKeepAliveRouterName(menuList, keepAliveArr) {
  menuList.forEach((item) => {
    item.meta.isKeepAlive && item.name && keepAliveArr.push(item.name)
    item.children?.length && getKeepAliveRouterName(item.children, keepAliveArr)
  })
  return keepAliveArr
}

/**
 * @description 使用递归，过滤出需要渲染在左侧菜单的列表（剔除 isHide == true 的菜单）
 * @param {Array} menuList 所有菜单列表
 * @return array
 * */
export function getShowMenuList(menuList) {
  let newMenuList = JSON.parse(JSON.stringify(menuList))
  return newMenuList.filter((item) => {
    item.children?.length && (item.children = getShowMenuList(item.children))
    return !item.meta?.isHide
  })
}

/**
 * @description 使用递归处理路由菜单 path，生成一维数组(第一版本地路由鉴权会用到)
 * @param {Array} menuList 所有菜单列表
 * @param {Array} menuPathArr 菜单地址的一维数组 ['**','**']
 * @return array
 */
export function getMenuListPath(menuList, menuPathArr) {
  menuList.forEach((item) => {
    typeof item === 'object' && item.path && menuPathArr.push(item.path)
    item.children?.length && getMenuListPath(item.children, menuPathArr)
  })
  return menuPathArr
}

/**
 * @description 递归找出所有面包屑存储到 pinia/vuex 中
 * @param {Array} menuList 所有菜单列表
 * @param {Object} result 输出的结果
 * @param {Array} parent 父级菜单
 * @returns object
 */
export const getAllBreadcrumbList = (menuList, result = {}, parent = []) => {
  for (const item of menuList) {
    result[item.path] = [...parent, item]
    if (item.children) getAllBreadcrumbList(item.children, result, result[item.path])
  }
  return result
}

/**
 * @description 格式化表格单元格默认值(el-table-column)
 * @param {Number} row 行
 * @param {Number} col 列
 * @param {String} callValue 当前单元格值
 * @return string
 * */
export function defaultFormat(row, col, callValue) {
  // 如果当前值为数组,使用 / 拼接（根据需求自定义）
  if (isArray(callValue)) return callValue.length ? callValue.join(' / ') : '/'
  return callValue ?? '/'
}

/**
 * @description 处理无数据情况
 * @param {String} callValue 需要处理的值
 * @return string
 * */
export function formatValue(callValue) {
  // 如果当前值为数组,使用 / 拼接（根据需求自定义）
  if (isArray(callValue)) return callValue.length ? callValue.join(' / ') : '/'
  return callValue ?? '/'
}

/**
 * @description 处理 prop 为多级嵌套的情况(列如: prop:user.name)
 * @param {Object} row 当前行数据
 * @param {String} prop 当前 prop
 * @return any
 * */
export function handleRowAccordingToProp(row, prop) {
  if (!prop.includes('.')) return row[prop] ?? '--'
  prop.split('.').forEach((item) => (row = row[item] ?? '--'))
  return row
}

/**
 * @description 处理 prop，当 prop 为多级嵌套时 ==> 返回最后一级 prop
 * @param {String} prop 当前 prop
 * @return string
 * */
export function handleProp(prop) {
  const propArr = prop.split('.')
  if (propArr.length == 1) return prop
  return propArr[propArr.length - 1]
}

/**
 * @description 根据枚举列表查询当需要的数据（如果指定了 label 和 value 的 key值，会自动识别格式化）
 * @param {String} callValue 当前单元格值
 * @param {Array} enumData 字典列表
 * @param {Array} fieldNames 指定 label && value 的 key 值
 * @param {String} type 过滤类型（目前只有 tag）
 * @return string
 * */
export function filterEnum(callValue, enumData, fieldNames) {
  const value = fieldNames?.value ?? 'value'
  const label = fieldNames?.label ?? 'label'
  let filterData = {}
  if (Array.isArray(enumData)) filterData = enumData.find((item) => item[value] === callValue)
  return filterData ? filterData[label] : ''
}

export function getIconFile(url) {
  let src = new URL(`/src/assets/${url}`, import.meta.url).href
  return src
}

export function formatPrice(value) {
  // 判断是否为数值类型，如果是字符串类型则转换为数值类型
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  // 判断数值是否大于等于10000
  if (value >= 10000) {
    // 将数值除以10000，并保留两位小数点
    value = _round(value / 10000, 2) + 'w'
  } else {
    value = _round(value, 2)
  }
  return value
}
// 格式化数字 保留{num}位小数,补零 三位一逗号
export function toLocaleString2(value, num = 2) {
  // 判断是否为数值类型，如果是字符串类型则转换为数值类型
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  return _round(value, num).toLocaleString('en-US', {
    minimumFractionDigits: num,
    maximumFractionDigits: num
  })
}
// 格式化数字 保留两位小数,不补零 三位一逗号
export function toLocaleString(value) {
  // 判断是否为数值类型，如果是字符串类型则转换为数值类型
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  return _round(value, 2).toLocaleString('en-US')
}

//计算百分比
export function formatPercent(value, total) {
  // 判断是否为数值类型，如果是字符串类型则转换为数值类型
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  const percentage = (value / total) * 100
  return _round(percentage, 2)
}
//根据传入的值获取选择的objID数组
export function getSelectedObjIDs(value) {
  let intValue = parseInt(value)
  let selectedObjIDs = []
  // 使用位运算来判断选择了哪些objID
  if (intValue & 1) {
    selectedObjIDs.push(1)
  }
  if (intValue & 10) {
    selectedObjIDs.push(10)
  }
  if (intValue & 100) {
    selectedObjIDs.push(100)
  }
  // 返回选择的objID数组
  return selectedObjIDs
}

/**
 * @Author: Sam
 * @Date: 2020-01-11 10:27:23
 * @Last Modified by: Sam
 * @Last Modified time: 2022-04-22 05:17:31
 */

/**
 * 判断是否为 null
 * @param {*} data
 * @returns
 */
export const isNull = (data) => !data && data != 0

/**
 * 判断变量是否为字符串
 * @param {*} data
 * @returns {Boolean}
 */
export const isString = (data) => typeof data === 'string'

/**
 * 空格处理
 * @param {String} str
 * @returns
 */
export const trim = (str) => (str || '').replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, '')

/**
 * 将字符串转换成对象格式
 * @param {String} str
 * @returns {Object} null
 */
export const strToObject = (str) => {
  var finalObj = null
  try {
    finalObj = JSON.parse(str)
  } catch (e) {
    finalObj = null
  }

  return finalObj
}

/**
 * clickOutside
 * 点击某元素外部事件指令
 */
export const clickOutside = {
  // 初始化事件
  beforeMount(el, binding, vnode) {
    function handleClick(e) {
      // 如果为元素本身，则返回
      if (el.contains(e.target) || e.target.className.includes(binding.arg)) {
        return false
      }

      // 如果绑定了函数，则返回执行
      binding.value(e)
    }
    el.__vueClickOutside__ = handleClick
    document.addEventListener('click', handleClick)
  },
  // 解除事件监听
  unmounted(el, binding) {
    document.removeEventListener('click', el.__vueClickOutside__)
    delete el.__vueClickOutside__
  }
}

/**
 * 函数节流
 */
export const throttle = (fn, delay) => {
  var lastTime
  var timer
  var delay = delay || 200
  return function () {
    var args = arguments
    // 记录当前函数触发的时间
    var nowTime = Date.now()
    if (lastTime && nowTime - lastTime < delay) {
      clearTimeout(timer)
      timer = setTimeout(function () {
        // 记录上一次函数触发的时间
        lastTime = nowTime
        // 修正this指向问题
        fn.apply(this, args)
      }, delay)
    } else {
      lastTime = nowTime
      fn.apply(this, args)
    }
  }
}

/**
 * 新增事件监听
 * @param {*} element
 * @param {*} event
 * @param {*} handler
 * @param {*} useCapture
 */
export const on = function (element, event, handler, useCapture = false) {
  if (element && event && handler) {
    element.addEventListener(event, handler, useCapture)
  }
}

/**
 * 移除事件监听
 * @param {*} element
 * @param {*} event
 * @param {*} handler
 * @param {*} useCapture
 */
export const off = function (element, event, handler, useCapture = false) {
  if (element && event && handler) {
    element.removeEventListener(event, handler, useCapture)
  }
}

/**
 * 判断是否为偶数
 * @param {*} num
 */
export const isEvnetNum = (num) => Number(num) % 2 === 0

/**
 * 将数值限制为整数
 * @param {*} v
 * @returns
 */
export const beInteger = (v) => {
  let val = v + ''
  return val.replace(/\D/g, '')
}

/**
 * 警告提示处理
 * @param {String} module
 * @param {*} info
 */
export const warn = (module, info) => {
  console.warn(`[ A warning of birdpaper-ui ] - ${module}: ` + info)
}

export const firstToUpper = (str) => {
  return str.trim().toLowerCase().replace(str[0], str[0].toUpperCase())
}

/**
 * @description 判断值是否为空，如果为空并且不等于0，则返回true
 * @param {Any} value 需要判断的值
 * @return boolean
 * */
export function isValueEmpty(value) {
  return (
    value === '' ||
    value === undefined ||
    value === null ||
    value === 'null' ||
    value === 'undefined'
  )
}

//获取文件后缀名
export function getFileSuffix(fileName) {
  let index = fileName.lastIndexOf('.')
  return fileName.substring(index + 1)
}

//去掉字符串中的标签
export function delHtmlTag(str) {
  return str.replace(/<[^>]+>/g, '') //去掉所有的html标记
}

import citieJson from "/public/cities.json";
/**
 *通过Code获取完整省市区名称
 * @export
 */
export function filterCityCode(config = {}) {
  //异步引入省市区数据
  let { province, city, district } = config;
  if (!province || !city || !district) return [];
  let citys = [].concat(citieJson);
  let arr = [];
  if (!province) return arr;
  let provinceList = citys.find((item) => item.value == province);
  arr.push(provinceList?.label ?? "--");
  if (!city) return arr;
  let cityList = provinceList?.children.find((item) => item.value == city);
  arr.push(cityList?.label ?? "--");
  if (!district) return arr;
  let districtList = cityList?.children.find((item) => item.value == district);
  arr.push(districtList?.label ?? "--");
  return arr;
}
